<template>
  <div>
    <!--Title Bar-->
    <title-bar
      v-if="section === 1"
      :title-value="'Institutions'"
    />

    <!-- Filter -->
    <v-toolbar
      flat
      dense
      color="accent"
    >
      <!--  Filter Icon -->
      <v-menu
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip
            right
            color="primary"
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                  v-if="showFiltered()"
                  color="primary"
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </template>

        <v-card width="400px">
          <v-card-title>
            <v-spacer />
            <v-btn
              v-if="showFiltered()"
              text
              rounded
              outlined
              block
              @click="clearFilter()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Clear Filters
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <!--Code-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="name"
                    label="Name"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Jurisdiction-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="id_jurisdiction"
                    :items="jurisdictionsStore.jurisdictionsValueList"
                    label="Jurisdiction"
                    placeholder=" "
                    item-text="name"
                    item-value="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-btn
              block
              rounded
              color="primary"
              @click="filter()"
            >
              <v-icon
                small
                left
              >
                fal fa-check
              </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!--Pagination  -->
      <pagination
        :show="institutionsStore.institutions.data && institutionsStore.institutions.data.length > 0 && institutionsStore.institutions.last_page > 1 ? true : false"
        :current-page="institutionsStore.institutions.current_page"
        :last-page="institutionsStore.institutions.last_page"
        @paginate-event="filter($event)"
      />

      <v-spacer />

      <!--Entry Dialog-->
      <institution
        v-if="section === 1"
        :section="section"
      />

      <!--Edit Project Institutions-->
      <project-institutions v-if="section === 2" />
    </v-toolbar>

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="institutionsStore.institutions.data && institutionsStore.institutions.data.length > 0 ? true : false"
        :list-data="institutionsStore.institutions.data"
        :title="'Institutions'"
        :section="section === 1 ? 4 : 4.1"
        :button-title="'Institution'"
        :actions="actions"
        @open-entry="openEntry($event)"
        @open-detail="openDetail($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import TitleBar from '@/components/TitleBar';
import Institution from '@/components/Institution';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';
import ProjectInstitutions from '@/components/ProjectInstitutions';

const { mapFields } = createHelpers({
  getterType: 'institutionsStore/getFilterField',
  mutationType: 'institutionsStore/updateFilterField',
});

export default {
  name: 'Institutions',
  components: {
    TitleBar,
    Institution,
    Pagination,
    ListBasic,
    ProjectInstitutions
  },
  /*1 = Settings Institutions, 2 = Project Institutions, 3 = Project Institutions*/
  props: {
    section: Number,
  },
  data() {
    return {
      filterMenu: false,
      actions: {
        menu: this.section === 1 ? 1 : 0,
        view: this.section === 1 ? 1  : 0,
        edit: this.section === 1 ? 1 : 0,
        password:0,
        invite:0,
        email:0
      },
    };
  },
  computed: {
    ...mapState({
      progressStore: state => state.progressStore,
      institutionsStore: state => state.institutionsStore,
      jurisdictionsStore: state => state.jurisdictionsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'page',
      'sectionStored',

      'id_project',
      'name',
      'id_jurisdiction',
      'flag_status',
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch('progressStore/set',true)

      this.changeSectionCheck();

      /*If Settings Institutions section, make sure project ID is cleared */
      if(this.section === 1) {
        this.id_project = '';
      }
      else {
        let project = this.$store.getters['projectsStore/getProject'];
        this.id_project = project.id;
      }
      /* Pull Jurisdictions For Value List */
      await this.$store.dispatch('jurisdictionsStore/valueList')

      this.sectionStored = this.section;

	    /* Grab filters */
	    const filters = this.$store.getters['institutionsStore/getFilters'];
      /* Grab institutions based on active filters */
      await this.$store.dispatch('institutionsStore/fetch', filters);
      this.$store.dispatch('progressStore/set',false)
    },

	  async filter(page) {
      this.filterMenu = false;
      this.page = page;
		  this.initialize();
    },
    
    clearFilterColumns() {
		  this.name = '';
      this.id_jurisdiction = '';
      this.flag_status = '';
      this.page = 1;
    },

	  async clearFilter() {
		  this.filterMenu = false;
      this.clearFilterColumns();
		  this.initialize();
    },

    changeSectionCheck() {
      if(this.sectionStored !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch('institutionsStore/clearInstitutions');
      }
    },

	  async openEntry(id) {
		  await this.$store.dispatch('institutionsStore/entry', id);
    },

    async openDetail(id) {
      this.$store.dispatch('progressStore/set',true)

      await this.$store.dispatch('institutionsStore/read', id);

      this.$router.push({name: 'institutionDetail', params: { id: id } });
    },
    
    showFiltered(){
      if(this.name || this.id_jurisdiction || (this.flag_status === 0 || this.flag_status === 1)) {
        return true
      }
    }
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
