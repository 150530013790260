<template>
  <div>
    <v-tooltip
      v-if="section === 1"
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="newEntry()"
        >
          <v-icon
            color="primary"
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Project</span>
    </v-tooltip>

    <v-tooltip
      v-if="section === 2"
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="openEntry(projectsStore.project.id)"
        >
          <v-icon
            color="primary"
          >
            fal fa-pencil
          </v-icon>
        </v-btn>
      </template>
      <span>Edit Project</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="900"
    >
      <v-card
        max-height="800"
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          Project Details
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
          >
            <v-container fluid>
              <v-row dense>
                <!--Title-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="title"
                    label="Title"
                    placeholder=" "
                    required
                    :rules="rules.title"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Short Name-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="short_name"
                    label="Short Name"
                    placeholder=" "
                    required
                    :rules="rules.short_name"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Managing PO-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="managing_po"
                    label="Managing PO"
                    placeholder=" "
                    required
                    :rules="rules.managing_po"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Cohort-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="id_cohort"
                    :items="cohortsStore.cohortsValueList"
                    label="Cohort"
                    placeholder=" "
                    item-text="name"
                    item-value="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.id_cohort"
                    @input="jurisdictionsByCohort(id_cohort)"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Jurisdiction-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-if="id_cohort"
                    v-model="jurisdictions"
                    :items="jurisdictionsStore.jurisdictionsValueList"
                    label="Jurisdictions"
                    placeholder=" "
                    item-text="name"
                    item-value="id"
                    clearable
                    multiple
                    chips
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.jurisdictions"
                  />
                  <v-text-field
                    v-else
                    label="Jurisdiction"
                    placeholder="Please select cohort before selecting Jurisdictions"
                    disabled
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Code-->
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                >
                  <v-text-field
                    v-model="code"
                    label="Code"
                    placeholder=" "
                    required
                    :rules="rules.code"
                  />
                </v-col>

                <!--Award ID-->
                <v-col
                  cols="9"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="award_id"
                    label="Award ID"
                    placeholder=" "
                  />
                </v-col>

                <!--NCE Flag-->
                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="flag_nce"
                    :items="valueListsStore.yesNo"
                    label="No Cost Extension period (NCE)"
                    placeholder=" "
                    item-text="value"
                    item-value="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Start Date-->
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="date_start"
                        label="Start Date"
                        placeholder=" "
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date_start"
                        v-on="on"
                        @blur="date1 = $_parseDate(date_start)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      color="primary"
                      @input="menu = false"
                    />
                  </v-menu>
                </v-col>

                <!--End Date-->
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="date_end"
                        label="End Date"
                        placeholder=" "
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date_end"
                        v-on="on"
                        @blur="date2 = $_parseDate(date_end)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date2"
                      color="primary"
                      @input="menu2 = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Start Date Baseline-->
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="date_start_baseline"
                        label="Baseline Start Date"
                        placeholder=" "
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date_start_baseline"
                        v-on="on"
                        @blur="date3 = $_parseDate(date_start_baseline)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date3"
                      color="primary"
                      @input="menu3 = false"
                    />
                  </v-menu>
                </v-col>

                <!--End Date Baseline-->
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="date_end_baseline"
                        label="Baseline End Date"
                        placeholder=" "
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date_end_baseline"
                        v-on="on"
                        @blur="date4 = $_parseDate(date_end_baseline)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date4"
                      color="primary"
                      @input="menu4 = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col
                  v-if="mode === 2"
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required:rules="flag_status === 0 ? [] : rules.flag_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';

const { mapFields } = createHelpers({
  getterType: 'projectsStore/getEntryField',
  mutationType: 'projectsStore/updateEntryField',
});

export default {
  name: 'Project',
  mixins: [mixin],
  props: {
    section: Number
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      rules: {
        title: [
          v => !!v || 'Required'
        ],
        short_name: [
          v => !!v || 'Required'
        ],
        managing_po: [
          v => !!v || 'Required'
        ],
        id_cohort: [
          v => !!v || 'Required'
        ],
        jurisdictions: [
          v => !!v || 'Required'
        ],
        code: [
          v => !!v || 'Required'
        ],
        date_start: [
			    v => !!v || 'Required',
        ],
        date_end: [
			    v => !!v || 'Required',
        ],
        date_start_baseline: [
			    v => !!v || 'Required',
        ],
		    date_end_baseline: [
			    v => !!v || 'Required',
        ],
        flag_status: [
			    v => !!v || 'Required',
        ],
      },
    }
  },
  computed: {
    ...mapState({
      projectsStore: state => state.projectsStore,
      cohortsStore: state => state.cohortsStore,
      jurisdictionsStore: state => state.jurisdictionsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'id',

      'id_cohort',
      'jurisdictions',
      'title',
      'short_name',
      'managing_po',
      'award_id',
      'code',
      'date_start',
      'date_end',
      'date_start_baseline',
      'date_end_baseline',
      'flag_nce',
      'flag_status',
      'date1',
      'date2',
      'date3',
      'date4'
    ]),
  },

  watch: {
    date1() {
      this.date_start = this.$_formatDate(this.date1);
    },
    date2() {
      this.date_end = this.$_formatDate(this.date2);
    },
    date3() {
      this.date_start_baseline = this.$_formatDate(this.date3);
    },
    date4() {
      this.date_end_baseline = this.$_formatDate(this.date4);
    },
  },

  methods: {

    async newEntry() {
      this.$store.dispatch('projectsStore/resetEntry');
      /* Pull Cohorts For Value List */
      await this.$store.dispatch('cohortsStore/valueList')
      /*Clear previous jurisdictions */
      this.$store.dispatch('jurisdictionsStore/clearStore')
      this.mode = 1;
      this.show = true;
    },

    
    async openEntry(id) {
      /* Pull Cohorts For Value List */
      await this.$store.dispatch('cohortsStore/valueList')
      await this.$store.dispatch('projectsStore/entry', id);
      let project = this.$store.getters['projectsStore/getEntry'];
      let data = {
        id_cohort: project.cohortID
      }
      await this.$store.dispatch('jurisdictionsStore/byCohort', data)
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters['projectsStore/getEntry'];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch('projectsStore/create', entry);
        }
        /* Edit */
        else {
          await this.$store.dispatch('projectsStore/update', entry);
        }
        this.cancelEntry();
      }
    },

    async jurisdictionsByCohort(cohortID) {
      this.jurisdictions=[]
      let data = {
        id_cohort: cohortID
      }
      await this.$store.dispatch('jurisdictionsStore/byCohort', data)
    }
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
