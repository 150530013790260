<template>
  <v-col
    cols="12"
    sm="12"
  >
    <v-toolbar
      class="pl-0"
      flat
      dense
      color="accent"
    >
      <!--Title Bar-->
      <v-toolbar-title>
        <title-bar 
          :title-value="projectsStore.project.title" 
          :reduce-x="1" 
        />
      </v-toolbar-title>

      <v-spacer />
      
      <project :section="2" />
    </v-toolbar>

    
    <v-card
      class="px-4"
      flat
      tile
      color="accent"
    >
      <v-row
        class="pb-6"
      >
        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            v-if="projectsStore.project.short_name"
            :display-string="projectsStore.project.short_name"
            :label="'Short Name'"
            side-label
          />
        </v-col>
        
        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display 
            v-if="projectsStore.project.managing_po"
            :display-string="projectsStore.project.managing_po"
            :label="'Managing PO'"
            side-label
          />
        </v-col>

        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            v-if="projectsStore.project.contactpi"
            :display-string="contactString"
            :label="'Contact PI'"
            side-label
          />
        </v-col>

        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            v-if="projectsStore.project.cohort"
            :display-string="projectsStore.project.cohort.name"
            :label="'Cohort'"
            side-label
          />
        </v-col>
          
        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            v-if="projectsStore.project.jurisdictions"
            :display-string="jurisdictionString"
            :label="'Jurisdictions'"
            side-label
          />
        </v-col>

        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            v-if="projectsStore.project.code"
            :display-string="projectsStore.project.code"
            :label="'Code'"
            side-label
          />
        </v-col>

        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            v-if="projectsStore.project.award_id"
            :display-string="projectsStore.project.award_id"
            :label="'Award ID'"
            side-label
          />
        </v-col>

        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            v-if="projectsStore.project.flag_nce"
            :display-string="$_yesNo(projectsStore.project.flag_nce)"
            :label="'No Cost Extension period (NCE)'"
            side-label
          />
        </v-col>
          
        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            v-if="projectsStore.project.date_start && projectsStore.project.date_end"
            :display-string="dateString"
            :label="'Dates'"
            side-label
          />
        </v-col>
      </v-row>
    </v-card>

    <v-tabs
      background-color="transparent"
    >
      <v-tabs-slider light />

      <v-tab
        :key="1"
        :href="'#tab-1'"
      >
        Users
      </v-tab>

      <v-tab-item
        :key="1"
        :value="'tab-1'"
      >
        <v-card
          flat
          color="accent"
        >
          <users
            :key="projectsStore.project.id"
            :section="3"
          />
        </v-card>
      </v-tab-item>
      
      
      <v-tab
        :key="2"
        :href="'#tab-2'"
      >
        Participation History
      </v-tab>

      <v-tab-item
        :key="2"
        :value="'tab-2'"
      >
        <v-card
          flat
          color="accent"
        >
          <participation :section="12" />
        </v-card>
      </v-tab-item>
      
      
      <v-tab
        :key="3"
        :href="'#tab-3'"
      >
        Institutions / Departments
      </v-tab>

      <v-tab-item
        :key="3"
        :value="'tab-3'"
      >
        <v-card
          flat
          color="accent"
        >
          <institutions :section="2" />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TextDisplay from '@/components/TextDisplay';
import Project from '@/components/Project';
import Institutions from '@/components/Institutions';
import Users from '@/components/Users';
import Participation from '@/components/Participation';

export default {
  name: 'ProjectDetail',
  components: {
    TitleBar,
    TextDisplay,
    Project,
    Institutions,
    Users,
    Participation,
  },
  mixins: [mixin],
  data() {
    return {
      dateString: '',
      contactString: '',
      jurisdictionString: ''
    }
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      projectsStore: state => state.projectsStore,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {

      await this.$store.dispatch('progressStore/set',true);

      const id_project = this.$route.params.id;

      await this.$store.dispatch('projectsStore/read', id_project);
      const p = this.$store.getters['projectsStore/getProject'];

      p.contactpi.forEach((contact,index) => {
        this.contactString += `${contact.name_reverse} (${contact.email})`;
        if (index + 1 < p.contactpi.length) {
          this.contactString += ', ';
        }
      });

      p.jurisdictions.forEach((jurisdiction,index) => {
        this.jurisdictionString += `${jurisdiction.name}`;
        if (index + 1 < p.jurisdictions.length) {
          this.jurisdictionString += '-';
        }
      });

      this.dateString = `${this.$_formatDate(p.date_start)} to ${this.$_formatDate(p.date_end)}`;

      await this.$store.dispatch('progressStore/set',false);
    },
  }
}
</script>

<style scoped>

</style>