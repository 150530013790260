<template>
  <div>
    <v-tooltip
      v-if="section === 1"
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="newEntry()"
        >
          <v-icon
            color="primary"
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Institution</span>
    </v-tooltip>

    <v-tooltip
      v-if="section === 2"
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="openEntry(institutionsStore.institution.id)"
        >
          <v-icon
            color="primary"
          >
            fal fa-pencil
          </v-icon>
        </v-btn>
      </template>
      <span>Edit Institution</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="900"
    >
      <v-card
        max-height="740"
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          Institution Details
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
          >
            <v-container fluid>
              <v-row dense>
                <!--Name-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="name"
                    label="Name"
                    placeholder=" "
                    required
                    :rules="rules.name"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!-- Jurisdiction -->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="id_jurisdiction"
                    :items="jurisdictionsStore.jurisdictionsValueList"
                    label="Jurisdiction"
                    placeholder=" "
                    item-text="name"
                    item-value="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.id_jurisdiction"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="mode !== 1"
                dense
              >
                <!--Status-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="flag_status === 0 ? [] : rules.not_empty"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';

const { mapFields } = createHelpers({
  getterType: 'institutionsStore/getEntryField',
  mutationType: 'institutionsStore/updateEntryField',
});

export default {
  name: 'Institution',
  mixins: [mixin],
  props: {
    /*1 = Institutions Settings, 2 = Institution Details, 3 = Project Institutions*/
    section: Number,
  },
  computed: {
    ...mapState({
      institutionsStore: state => state.institutionsStore,
      jurisdictionsStore: state => state.jurisdictionsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'id',

      'name',
      'id_jurisdiction',
      'flag_status'
    ]),
  },
  data() {
    return {
      rules: {
        name: [
			    v => !!v || 'Required',
        ],
        id_jurisdiction: [
			    v => !!v || 'Required',
        ],
        flag_status: [
			    v => !!v || 'Required',
        ],
      },
    }
  },
  methods: {

    async newEntry() {
      this.$store.dispatch('institutionsStore/resetEntry');
      this.mode = 1;
      this.show = true;
    },

    async openEntry(id) {
		  await this.$store.dispatch('institutionsStore/entry', id);
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters['institutionsStore/getEntry'];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch('institutionsStore/create', entry);
        }
        /* Edit */
        else {
          await this.$store.dispatch('institutionsStore/update', entry);
        }
        this.cancelEntry();
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
