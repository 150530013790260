<template>
  <v-col
    cols="12"
    sm="12"
  >
    <v-toolbar
      class="pl-0"
      flat
      dense
      color="transparent"
    >
      <!--Title Bar-->
      <v-toolbar-title>
        <title-bar
          :title-value="usersStore.user.name"
          :reduce-x="1"
        />
      </v-toolbar-title>

      <v-spacer />

      <user :section="11" />
    </v-toolbar>

    
    <v-card
      class="px-4"
      flat
      tile
      color="accent"
    >
      <v-row dense>
        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            :display-string="usersStore.user.email"
            :label="'Email'"
            side-label
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            :display-string="usersStore.user.flag_admin === 1 ? 'Yes' : 'No'"
            :label="'Site Admin'"
            side-label
          />
        </v-col>
      </v-row>

      <v-row v-if="usersStore.user.flag_admin === 1" dense>
        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            :display-string="usersStore.user.flag_hide === 1 ? 'Yes' : 'No'"
            :label="'Hide In User Lists'"
            side-label
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          class="pt-2 pb-0"
          cols="12"
          sm="12"
        >
          <text-display
            :display-string="usersStore.user.flag_status === 1 ? 'Has Access' : 'No Access'"
            :label="'Access'"
            side-label
          />
        </v-col>
      </v-row>
    </v-card>

    <v-tabs
      background-color="transparent"
    >
      <v-tabs-slider light />
      
      <v-tab
        :key="1"
        :href="'#tab-1'"
      >
        Participation History
      </v-tab>

      <v-tab-item
        :key="1"
        :value="'tab-1'"
      >
        <v-card
          flat
          tile
        >
          <participation :section="usersStore.appMode === 2 ? 11 : 13" />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import TextDisplay from '@/components/TextDisplay';
import User from '@/components/User';
import Participation from '@/components/Participation';

export default {
  name: 'UserDetail',
  components: {
    TitleBar,
    TextDisplay,
    User,
    Participation,
  },
  mixins: [mixin],
  data() {
    return {
    
    }
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
    }),
    
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {

      await this.$store.dispatch('progressStore/set',true);

      const id_user = this.$route.params.id;

      await this.$store.dispatch('usersStore/read', id_user);

      this.$store.dispatch('progressStore/set',false);
    },

    async openParticipantEntry(id) {
      await this.$store.dispatch('participantsStore/entry', id);
    },
  }
}
</script>

<style scoped>

</style>