<template>
  <div>
    <v-tooltip
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="openEntry()"
        >
          <v-icon
            color="primary"
          >
            fal fa-pencil
          </v-icon>
        </v-btn>
      </template>
      <span>Edit Institutions / Departments</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="800"
    >
      <v-card
        max-height="880"
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          Project Institutions / Departments
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col
                cols="12"
                sm="12"
              >
                <v-list dense>
                  <v-list-group
                    v-for="(institution,index) in institutionsStore.institutionsValueList"
                    :key="index"
                    no-action
                    sub-group
                  >
                    <template #activator>
                      <v-list-item-content>
                        <v-checkbox
                          v-model="institutions"
                          dense
                          color="primary"
                          multiple
                          :label="institution.name"
                          :value="institution.id"
                          class="pr-2 py-0"
                        />
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-for="(department,i) in institution.departments"
                      :key="i"
                      link
                    >
                      <v-checkbox
                        v-model="departments"
                        dense
                        color="primary"
                        multiple
                        :label="department.name"
                        :value="department.id"
                        class="pr-2 py-0"
                      />
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers, mapMultiRowFields } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';

const { mapFields } = createHelpers({
  getterType: 'projectsStore/getEntryInstitutionsField',
  mutationType: 'projectsStore/updateEntryInstitutionsField',
});

export default {
  name: 'ProjectInstitutions',
  mixins: [mixin],
  props: {
    section: Number
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      institutionsStore: state => state.institutionsStore,
    }),
    ...mapFields([
      'show',
      'id',

      'institutions',
      'departments'
    ]),
  },

  methods: {
    
    async openEntry() {
      let project = this.$store.getters['projectsStore/getProject'];
      let $data = []
      var i;
      for (i = 0; i < project.jurisdictions.length; i++) { 
        $data.push(project.jurisdictions[i].id)
      }
      await this.$store.dispatch('institutionsStore/byJurisdiction',$data)
      await this.$store.dispatch('projectsStore/entryInstitutions', project.id);
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      let project = this.$store.getters['projectsStore/getEntryInstitutions'];
      await this.$store.dispatch('projectsStore/updateInstitutions',project);
      /* Grab filters */
	    const filters = this.$store.getters['institutionsStore/getFilters'];
      /* Grab institutions based on active filters */
      await this.$store.dispatch('institutionsStore/fetch', filters);
      this.cancelEntry();
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
